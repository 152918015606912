<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :popupComponent="popupComponent"
        title="Manual Credit List"
        icon="mdi-bank"
        sort="refno"
        :basic-data="basicData"
        :descending="true"
        addButtonText="Credit"
        stateendpoint="credit.credits"
        :show-default-action-buttons="false"
        :actions="actions"
        :action-buttons="actionButton"
    />
  </div>
</template>
<script>
import ListPage from "../components/ListPage";
import ManualCreditPopup from "../components/popups/ManualCreditPopup";

export default {
  name: 'ManualCredits',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Manual Credit List | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage, ManualCreditPopup},
  data() {
    return {
      headers: [
        {
          text: 'Ref No',
          value: 'refno'
        },
        {
          text: 'Name',
          value: 'user.name'
        },
        {
          text: 'Email',
          value: 'user.email'
        },
        {
          text: 'Method',
          value: 'methods.name'
        },
        {
          text: 'Amount',
          value: 'amount'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      columns: [
        {value: 'refno'},
        {value: 'amount'},
        {value: 'action'},
      ],
      actions: {
        load: 'loadCredits',
        create: 'storeCredit',
        remove: 'removeCredit',
        destroy: 'destroyCredit'
      },
      basicData: {
        amount: 0
      },
      actionButton: [
        {
          category: 'delete',
          icon: 'mdi-delete',
          color: 'error',
          text: 'Delete',
          clickHandler: 'deleteItem'
        },
        {
          category: 'destroy',
          icon: 'mdi-delete-forever',
          color: 'error',
          text: 'Delete Forever',
          clickHandler: 'destroyItem'
        },
      ]
    }
  },
  computed: {
    popupComponent() {
      return ManualCreditPopup;
    }
  }
}
</script>
