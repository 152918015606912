<template>
  <base-popup
      :popup-model="show"
      :title="formTitle"
      :valid="!valid"
      @onSaveButtonClick="saveItemHandler"
      @onCancelButtonClick="closePopupHandler"
  >
    <v-form
        :ref="formRef"
        v-model="valid"
        lazy-validation
    >
      <v-row class="mt-5 mb-0 mr-3">
        <v-col cols="12">
            <g-p-auto-complete
                :dense="true"
                :outlined="true"
                icon="mdi-account"
                search-label="Select User"
                :search-value="currentItem.user"
                :status=true
                item-text="email"
                sort-by="email"
                @selectSearchResult="onSelections('user',$event)"
                @clearSearchResult="handleClearSearchResult('user')"
                apiPath="get-users"
            />
            <g-p-auto-complete
                :dense="true"
                :outlined="true"
                icon="mdi-contactless-payment"
                search-label="Select Methods"
                :search-value="currentItem.methods"
                :status=true
                @selectSearchResult="onSelections('methods',$event)"
                @clearSearchResult="handleClearSearchResult('methods')"
                apiPath="methods"
            />
            <v-text-field
                v-model.number="currentItem.amount"
                :rules="numberRule"
                class="right-align-text"
                label="Price"
                onclick="this.select()"
                prepend-icon="attach_money"
                outlined
                dense
                required
                type="number"
            />
        </v-col>
      </v-row>
    </v-form>
  </base-popup>
</template>
<script>
import BasePopup from "./BasePopup";
import PopupMixin from "../mixins/Popup";
import GPAutoComplete from "../GPAutoComplete";

export default {
  name: 'ManualCreditPopup',
  components: {GPAutoComplete, BasePopup},
  mixins: [PopupMixin],
  computed: {
    formTitle() {
      return this.isEditMode
          ? 'Edit Manual Credit'
          : 'Add Manual Credit'
    }
  }
}
</script>
